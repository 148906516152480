.autocomplete-wrapper {
  width: 350px;
  position: relative;
  display: inline-block;
  color: black;
}

.autocomplete-wrapper > div {
  width: 100%;
  color: black;
}

.autocomplete-wrapper input {
  border: 1px solid #cecece;
  width: 100%;
  min-height: 40px;
  color: black;
  padding: 0 20px;
}

.autocomplete-wrapper input:focus {
  border-color: #0f67ff;
  box-shadow: none;
  outline: none;
  color: black;
}

.autocomplete-wrapper .dropdown {
  width: 100%;
  padding: 0;
  text-align: left;
  overflow: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #0f67ff;
  border-top: none;
  color: black;
}

.autocomplete-wrapper .item {
  display: block;
  cursor: pointer;
  font-size: 14px;
  padding: 15px;
  color: black;
}

.autocomplete-wrapper .item.selected-item {
  background-color: #0069ff;
  color: black;
}

.autocomplete-wrapper .item:hover {
  background-color: #0069ff;
  color: black;
}

.search-box {
  background-color: #ffffff;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid gray;
}

.text-search {
  padding-top: 2px;
  padding-bottom: 2px;
}

.text-search:hover {
  background-color: #0069ff;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
