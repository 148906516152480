@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

html,
body,
#root {
  height: 100%;
  box-sizing: border-box;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
  margin: 0;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursor-pointer:hover {
  cursor: pointer;
  opacity: 0.5;
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.font12 {
  font-size: 12px;
}

.font14 {
  font-size: 14px;
}

.font16 {
  font-size: 16px;
}

.font18 {
  font-size: 18px;
}

.font20 {
  font-size: 20px;
}

.font22 {
  font-size: 22px;
}

.font24 {
  font-size: 24px;
}

.font-weight-400 {
  font-weight: 400;
}
.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.no-padding {
  padding: 0 !important;
}

.text-green {
  color: #00bd84;
}

.bg-green {
  background: #00bd84;
}
