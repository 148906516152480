@import '../../../assets/scss/abstracts/variables.scss';

.content-home-notfound {
  display: block;
  p{
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 2em;
    text-align: center;
    color: white;
  }
  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }
  
  .centered-text {
    position: fixed;
    top: 25%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }
  
  img{
    width: 480px;
    height: auto
  }
  @media only screen and (max-width:400px){
    p{
      font-size: 20px;
    }
    img{
      width: 400px;
      height: auto
    }
  
    .centered-text{
      width: 100%;
    }
  
    .centered {
      top:55%
    }
  }
}
