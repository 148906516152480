@import '../../../assets/scss/abstracts/variables.scss';

.label-legend {
  font-weight: 500;
  font-size: 10px;
  color: #000000;
}

.grid-container {
  display: grid;
  grid-template-columns: 135px 135px 135px 135px 135px 135px 135px 135px;
}

.grid-item {
  text-align: center;
  border-radius: 20px;
  text-align: center;
  padding: 9px 0;
}

.mobile-grid-container {
  display: grid;
  grid-template-columns: 100px 80px 80px 80px;
  font-size: 10px;
}

.mobile-grid-item {
  text-align: center;
  border-radius: 20px;
  text-align: center;
  padding: 4px 0;
}

.empty-slot {
  background: #c0c1c0;
}

.available-slot {
  background: $color-blue2 !important;
}

.waiting-list-slot {
  border: 2px solid #d61417;
}

.content-result-find-doctor {
  .title {
    margin-top: 40px;
    margin-bottom: 54px;
  }
  h1 {
    font-size: 48px;
  }
  .form-row > .col,
  .form-row > [class*='col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .fa-minus {
    color: $color-grey2;
    font-size: 10px;
  }
  .start-grid {
    padding-left: 0px !important;
  }
  .end-grid {
    padding-right: 0px !important;
  }

  .input-group-text {
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    border-color: $font-white;
    background: $font-white;
  }

  .btn-search {
    padding: 5px 32px;
    margin-left: 12px;
    border: none;
  }

  .card-detail-doctor {
    margin-bottom: 24px;
  }

  .fa-arrow-circle-right,
  .fa-map-marker {
    color: $color-blue;
  }

  .list-doctor {
    .photo-doctor {
      //margin:0;

      img {
        margin: 0.5em auto;
      }
    }

    .photo-procedures {
      //margin:0;

      img {
        margin: 0.5em auto;
        width: 100%;
        height: auto;
      }
    }


    .card {
      color: $font-black;
      border: 20px;
      .card-header {
        background: $font-white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 16px 16px;
        .name-doctor {
          font-size: 24px;
          margin-top: 12px;
          font-weight: 700;
        }
        .name-procedures {
          font-size: 16px;
          margin-top: 12px;
          font-weight: 700;
        }
        .spesialis-doctor {
          font-size: 16px;
          margin-top: 8px;
          font-weight: 600 !important;
        }
        .accordion-doctor {
          font-size: 10px;
          margin-top: 5px;
          color: $color-primary;
        }

        .location-doctor {
          font-size: 16px;
          //color: $color-primary;
          margin-top: 5px;
          font-weight: 600;
        }

        .date-another-doctor {
          //margin-top: 16px;
          Button {
            padding: 8px 32px;
            font-size: 16px;
            width: 365px;
          }
        }
      }
      .header-card {
        border-radius: 20px;
        border: 1px solid $color-grey1;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .card-body {
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        table {
          margin-bottom: 0px;
        }
        .title {
          margin: 0;
          font-size: 16px;
          background: $color-grey5;
          padding: 8px 0 8px 15px;
          font-weight: 400;
        }
        .date-doctor {
          border-bottom: 2px solid;
          border-color: $color-grey5;

          .list-date-doctor {
            margin: 24px 88px;
          }
        }

        .time-doctor-bpjs {
          border-bottom: 2px solid;
          border-color: $color-grey5;

          .list-time-doctor-bjps {
            margin: 24px 88px;
          }
        }

        .submit-order {
          margin-top: 24px;
          button {
            font-size: 12px;
            border-radius: 20px;
            padding: 8px 24px;
          }
        }

        button {
          font-size: 12px;
          padding: 8px 24px;
          margin-right: 24px;
        }

        .legend {
          font-size: 10px;
          font-weight: 400;
          .row {
            margin-left: 0px;
            fa-circle {
              font-size: 10px;
              text-align: center;
            }
          }
        }
        // .available {
        //     color: $color-blue2 !important;
        //     background: none;
        // }
        .full {
          color: $color-blue2 !important;
          background: none;
        }
        .unavailable {
          color: $color-grey7 !important;
          background: none;
        }
      }
    }
  }
  .detail-doctor {
    margin-left: 90px;
    margin-bottom: 24px;
    .title {
      font-size: 14px !important;
      margin-bottom: 8px;
    }
    .content {
      img {
        margin-right: 6px;
      }
      font-size: 12px;
      text-align: left;
      ul {
        margin-left: 15px;
      }
    }
  }

  table {
    th,
    tr,
    td {
      width: 12.5%;
      border: none;
      height: 52px;
      align-items: center;
      p {
        font-size: 12px;
        color: $font-black;
      }
    }
    td {
      font-weight: 400;
    }
  }

  .list-date-availble {
    margin-bottom: -15px !important;
    .date-availble {
      height: 100%;
    }
  }

  .legend-container {
    margin-top: 10px;
    p {
      font-size: 8px;
      font-weight: 600;
      color: $font-black !important;
      margin-right: 10px;
    }
    .legend {
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 20px;
      margin-right: 5px;
    }
  }
  .legend-date {
    display: block;
    border-radius: 20px;
  }

  .available {
    background-color: $color-blue2 !important;
  }
  .full-waiting {
    background-color: $font-white;
    border: 1px solid $color-red1;
  }
  .full {
    background-color: $color-grey1 !important;
  }
  .unavailable {
    color: $color-grey7 !important;
    font-size: 35px !important;
    font-weight: 600 !important;
    margin: -8px 5px 0px 0px;
    vertical-align: top !important;
  }
}

footer {
  p {
    position: none;
  }
}

.legend-container {
  margin-top: 10px;
  p {
    font-size: 10px;
    font-weight: 500;
    color: $font-black !important;
    margin-right: 10px;
  }
  .legend {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 20px;
    margin-right: 5px;
  }
  .available {
    background-color: $color-blue2;
  }
  .full-waiting {
    background-color: $font-white;
    border: 1px solid $color-red1;
  }
  .full-reservesed {
    background-color: $color-grey1;
  }
  .unavailable-icon {
    background-color: $color-grey7;
  }
}

.header-mobile-card {
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.doctor-name {
  font-size: 11px;
}

.mobile-sub-menu-title {
  color: black;
  background: #ededed;
  border-radius: 8px;
}

.mobile-schedule-date-doctor {
  background: #ffffff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.mobile-date-availble {
  font-weight: 400;
  font-size: 9px;
  color: #000000;
}

.title-table-sub-value {
  font-weight: 600;
  font-size: 10px;
  color: #000000;
}

.hour-date {
  font-size: 9px;
}

.consultation-type {
  font-size: 16px;
  .enabled {
    margin-left: 12px;
    color: $font-black;
  }
  .disabled {
    margin-left: 12px;
    color: $color-grey1;
  }
}

.enabled {
  color: $font-black;
}
.disabled {
  color: $color-grey1;
}

/* Mobile View */
.mobile-card-doctor {
  background: #ffffff;
  border-radius: 20px;
  padding: 16px;
}

.mobile-inner-card-doctor {
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  padding: 16px;
}

.mobile-doctor-name {
  font-weight: 700;
  font-size: 24px;
  color: #424242;
}

.mobile-sub-title {
  font-weight: 600;
  font-size: 18px;
  color: #343434;
}

@media (min-width: 1200px) { 
  .col-lg-eq-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}