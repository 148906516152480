.accordion-title {
  height: 40px;
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #cccccc;
}

.accordion-icon {
  position: absolute;
  right: 9%;
}
