table {
  width: 100%;
  border-collapse: collapse;
  .th {
    border-top: 1px solid #dedede;
    padding: 4px 4px;
    font-weight: bold;
  }
  td {
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
    padding: 2px 4px;
  }
  tr {
    td {
      img {
        width: 24px;
      }
    }
  }
  #bi-th {
    height: 50px;
  }
}

.table-sales {
  width: auto;
  font-size: 16px;
  &.table-sku {
    margin: 0 auto;
    width: 100%;
  }
  thead {
    background: #cfcfcf;
  }
  th {
    margin: 5px 3px;
    border: 0.5px solid white;
    padding: 3px 7px;
  }
  tr {
    border: 0.5px solid white;
  }
  td {
    padding: 3px 7px;
  }
}

$breakpoint-non-hd: 1366px;
@media screen and (max-width: $breakpoint-non-hd) {
  table {
    .th {
      padding: 2px 4px;
    }
    td {
      padding: 0px 4px;
    }
    tr {
      td {
        img {
          width: 18px;
        }
      }
    }
    #bi-th {
      height: 34px;
    }
  }
}
