.modal-container-lg {
  padding-right: 470px;
}

.modal-lg {
  min-width: 1000px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.modal-md {
  min-width: 700px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.modal-comp-container {
  max-height: 400px;
  overflow-y: auto;
}

@media only screen and (max-width: 1024px) {
  .modal-container-lg {
    padding-right: 0px;
  }
  
  .modal-lg,
  .modal-md,
  .modal-sm {
    min-width: 90vw;
    display: flex;
    margin-left: 6vw!important;
  }
  
  .modal-comp-container {
    max-height: 465px;
    overflow-y: auto;
  }

  .no-padding-mobile {
    padding: 0px!important;
  }
}

.img-head-close {
  right: 17px;
}

.img-head-close:hover {
  cursor: pointer;
}