@import "../../../../../assets/scss/abstracts/variables.scss";

header {
  .navbar {
    background: transparent;
    box-shadow: none;
  }
  .btn-signup {
    .fa {
      margin-left: 8px;
    }
  }
  .link-login {
    font-weight: bold;
    color: $font-white !important;
    font-size: 18px;
    margin-right: 23px;
  }

  .avatar {
    display: inline-block !important;
    .circle-image-avatar {
      position: relative;
      -webkit-border-radius: 40px;
      -moz-border-radius: 40px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      z-index: 0;
    }

    .circle-image-avatar:after {
      position: absolute;
      display: block;
      top: -2px;
      left: -2px;
      width: 40px;
      height: 40px;
      content: "";
      background-color: $font-white;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      overflow: hidden;
      z-index: -1;
    }

    .image-avatar {
      margin-top: -13%;
      margin-left: -33%;
    }
  }

  .username {
    font-weight: bold;
    color: $font-white !important;
    font-size: 18px;
    margin-left: 15px;
    display: inline-block !important;
    margin: 0 11px;
  }

  @media only screen and (max-width: 600px) {
    .username {
      font-size: 16px;
    }
  }
}

input {
  border-radius: 0px !important;
}

.modal-dialog {
  max-width: 30% !important;
  color: black;
  font-weight: 400;
  font-size: 20px;

  @media only screen and (max-width: 600px) {
    margin-left: -5px !important;
    padding: 10px !important;
  }
}

.modal-content {
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important;
}

.modal-container {
  padding: 40px 50px;
  text-align: center;
  p,
  h1 {
    color: ($font-black);
  }
  h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
  }
  p {
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  p strong {
    font-weight: 700;
  }

  p .green {
    color: ($color-primary);
  }

  .input-countrycode {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    border: 1px solid ($color-grey1);
    border-radius: 50px;
    padding: 4px 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ($color-grey1);
    p {
      margin-bottom: 10px;
    }
  }

  .img-flag {
    padding-right: 10px;
  }

  .input-icons {
    width: 300px;
    position: relative;

    .input-phoneNumber {
      width: 100%;
      border: 1px solid ($color-grey1);
      border-radius: 50px;
      padding: 4px 20px 4px 20px;
      border-radius: 20px;
      font-size: 16px;
      height: 40px;
    }

    .buttonsubs {
      position: absolute;
      float: left;
      left: 260px;
      top: 8px;
      width: 24px;
      height: 24px;
      z-index: 100;
    }

    .buttonsubs:hover {
      cursor: pointer;
    }
  }

  .pincode-input-text {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0px !important;
    border-bottom: 1px solid $color-primary !important;
    padding-bottom: 2px;
    font-size: 40px;
    color: $color-primary !important;
  }

  @media only screen and (max-width: 600px) {
    .input-countrycode {
      max-width: 100%;
      padding: 0 15px;
      height: 42px;
    }

    .input-phoneNumber {
      margin-top: 5px;
      font-size: 16px !important;
    }

    .input-icons {
      .buttonsubs {
        left: 83%;
        top: 30%;
      }
    }

    .pincode-input-text {
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      border-radius: 0px !important;
      border-bottom: 1px solid $color-primary !important;
      width: 35px !important;
      height: 35px !important;
      padding-bottom: 2px;
      font-size: 20px;
      color: $color-primary !important;
    }

    h1 {
      font-weight: 700;
      font-size: 28px;
      line-height: 38px;
    }

    p {
      margin-top: 5px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }

    label {
      font-size: 16px !important;
    }
  }
}

.information-tnc {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.information-phone {
  font-weight: 700;
}

@media screen and (max-width: 600px) {
  .modal-container {
    padding: 24px 14px;
  }

  .information-phone {
    font-weight: 400;
  }
}

.modal-dialog-centered {
  @media only screen and (max-width: 600px) {
    align-items: start !important;
    margin-top: 35%;
  }
}

.modal-open .modal {
  @media only screen and (max-width: 600px) {
    overflow-y: hidden !important;
  }
}

label {
  font-weight: 500;
}

.pt-navbar {
  padding-top: 1rem;
}

.container-nav {
  display: block;
}
.container-nav-mobile {
  display: none;
}

@media only screen and (min-width: 321px) and (max-width: 790px) {
  .container-nav {
    display: none;
  }
  .container-nav-mobile {
    display: block;
  }
}

@media only screen and (min-width: 321px) and (orientation: landscape) {
  .pt-navbar {
    padding-top: 2px;
  }
}

@media only screen and (min-width: 810px) and (orientation: portrait) {
  .container-nav {
    display: block;
  }
  .container-nav-mobile {
    display: none;
  }
}
