.button-sm-primary,
.button-sm-primary-blue,
.button-sm-danger,
.button-sm-white,
.button-sm-outline-primary,
.button-sm-outline-danger {
  height: 30px;
}

.button-md-primary,
.button-md-primary-blue,
.button-md-danger,
.button-md-white,
.button-md-outline-primary,
.button-md-outline-danger {
  height: 40px;
}

.button-lg-primary,
.button-lg-primary-blue,
.button-lg-danger,
.button-lg-white,
.button-lg-outline-primary,
.button-lg-outline-danger {
  height: 55px;
}

.button-xl-primary,
.button-xl-primary-blue,
.button-xl-danger,
.button-xl-white,
.button-xl-outline-primary,
.button-xl-outline-danger {
  height: 65px;
}

.button-sm-primary,
.button-sm-primary-blue,
.button-sm-danger,
.button-sm-white,
.button-md-primary,
.button-md-primary-blue,
.button-md-danger,
.button-md-white,
.button-lg-primary,
.button-lg-primary-blue,
.button-lg-danger,
.button-lg-white,
.button-xl-primary,
.button-xl-primary-blue,
.button-xl-danger,
.button-xl-white {
  background: #00bd84;
  border-radius: 50px;
  border: 1px solid #00bd84;
  color: #ffffff;
}

.button-sm-danger,
.button-md-danger,
.button-lg-danger,
.button-xl-danger {
  background: #dc2626;
  border: 1px solid #dc2626;
}

.button-sm-primary-blue,
.button-md-primary-blue,
.button-lg-primary-blue,
.button-xl-primary-blue {
  background: #007aff;
  border: 1px solid #007aff;
}

.button-sm-white,
.button-md-white,
.button-lg-white,
.button-xl-white {
  background: white;
  border: 1px solid #cccccc;
  color: black;
}

.button-sm-white:hover,
.button-md-white:hover,
.button-lg-white:hover,
.button-xl-white:hover {
  background: #e5e5e5;
}

.button-sm-primary:hover,
.button-sm-danger:hover,
.button-sm-primary-blue:hover,
.button-md-primary:hover,
.button-md-danger:hover,
.button-md-primary-blue:hover .button-lg-primary:hover,
.button-lg-danger:hover,
.button-lg-primary-blue:hover .button-xl-primary:hover,
.button-xl-danger:hover,
.button-xl-primary-blue:hover {
  cursor: pointer;
  opacity: 0.7;
}

.btn-disable {
  background: #e5e5e5;
  border: 1px solid #e5e5e5;
  cursor: not-allowed;
}

.btn-disable:hover {
  cursor: not-allowed;
  opacity: 1;
}

// outline
.button-sm-outline-primary,
.button-sm-outline-danger,
.button-md-outline-primary,
.button-md-outline-danger,
.button-lg-outline-primary,
.button-lg-outline-danger,
.button-xl-outline-primary,
.button-xl-outline-danger {
  background: #ffffff;
  border-radius: 50px;
  border: 1px solid #00bd84;
  font-weight: 600;
  font-size: 16px;
  color: #00bd84;
}

.button-sm-outline-danger,
.button-md-outline-danger,
.button-lg-outline-danger,
.button-xl-outline-danger {
  border: 1px solid #dc2626;
  color: #dc2626;
}

.button-sm-outline-primary:hover,
.button-sm-outline-danger:hover,
.button-md-outline-primary:hover,
.button-md-outline-danger:hover,
.button-lg-outline-primary:hover,
.button-lg-outline-danger:hover,
.button-xl-outline-primary:hover,
.button-xl-outline-danger:hover {
  cursor: pointer;
  opacity: 0.7;
}
