.textarea,
.textarea-warning {
  min-height: 80px;
  border: 1px solid #cccccc;
  border-radius: 16px;
  font-weight: 400;
  color: #343434;
  padding: 10px;
  font-size: 16px;
}

.textarea-warning {
  border: 1px solid #ff0000;
}

.label-input {
  font-weight: 600;
  font-size: 16px;
  color: #343434;
  margin-bottom: 8px;
}

.text-size {
  font-weight: 400;
  font-size: 16px;
  color: #cccccc;
}

.warning-message {
  font-weight: 400;
  font-size: 16px;
  color: #ff0000;
  font-style: italic;
}
