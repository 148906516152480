.container-field {
  position: relative;
  width: 100%;
}

.input-custom-date,
.input-custom-date-warning {
  min-height: 40px;
  border: 1px solid #cccccc;
  border-radius: 50px;
  font-weight: 400;
  color: #343434;
  font-size: 16px;
  padding-left: 17px;
  padding-right: 17px;
}

.label-input-date {
  font-weight: 600;
  font-size: 16px;
  color: #343434;
  margin-bottom: 8px;
}

.input-custom-date-warning {
  border: 1px solid #ff0000;
}

.text-sm {
  font-size: 10.5px;
}

.text-md {
  font-size: 12px;
}

.text-lg {
  font-size: 16px;
}
