.image-dokter {
  display: block;
  position: absolute;
  float: right;
  // width: 550px;
}
.image-dokter-mobile{
  display: none;
}
@media screen and (max-width: 768px) {
  .image-dokter{
    position: relative;
    // right: 50%;
  }
}
//potrait mobile

@media only screen
and (min-width : 321px) and (max-width : 790px) {
  .image-dokter-mobile{
    display: block;
  }
  .image-dokter{
    display: none;
  }
}

// landscape mobile
@media only screen
and (min-width : 321px) and (max-width : 790px) and (orientation: landscape){
  .image-dokter-mobile{
    display: none;
  }
  .image-dokter{
    display: none;
  }
}

// potrait tablet
@media only screen
and (min-width : 800px) and (max-width : 1024px) and (orientation:portrait){
  .image-dokter-mobile{
    display: block;
    width: 420px;
    height: auto
  }
  
}
//landscape tablet
@media only screen
and (min-width : 800px) and (max-width : 1280px) and (orientation: landscape){
  .image-dokter{
    right: 1px;
    top: 9.5em;
    width:45%;
    height:auto;
  }
}
//big screen
@media only screen
and (min-width : 1281px) {
  .image-dokter {
    bottom: -20px;
    float: right;
    right: 80px;
    // width: 550px;
  }

}