@import "../../../assets/scss/abstracts/variables.scss";

.color-gray {
  background: #d0d0d0;
  border-radius: 10px;
}

.container-transaction-history-mobile {
  width: 100%;
  background: white;
  border-radius: 10px;
  border: none;
  padding: 20px 10px;
}

.card-transaction-history-mobile {
  width: 100%;
  background: white;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
}

.content-transaction-history {
  background-color: #e6f8f3 !important;
  padding: 10px;
  border-radius: 15px;

  .btn-show-consultation-history {
    color: #000000 !important;
    background-color: #ffffff !important;
    font-size: 12px;
    padding: 8px 24px 8px 24px;
    height: 40px;
  }

  .title {
    padding: 15px;
    display: inline-block !important;

    span {
      color: $font-black !important;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      display: inline-block !important;
    }

    .select-option {
      display: inline-block !important;
      width: 200px;
      margin: 0px 0px 0px 30px;
    }

    .schedule-date {
      display: inline-block !important;
      width: 200px;
      margin: 0px 0px 0px 30px;
    }
  }

  .card {
    margin: 20px 15px 20px 15px;
    border: 1px solid $color-grey1 !important;
    border-radius: 10px 10px 10px 10px;

    .header {
      background-color: $font-white !important;
      display: inline-block !important;
      color: $font-black !important;
      border-radius: 10px 10px 0px 0px;

      .header-1 {
        display: inline-block !important;
        margin: 15px 15px 15px 15px;
      }

      .btn-right-1 {
        position: absolute;
        right: 0;
        margin-right: 20px;
        font-size: 12px;
        margin-top: 20px;
        display: inline-block !important;
      }

      .btn-right-2 {
        position: absolute;
        right: 0;
        margin-right: 25px;
        font-size: 17px;
        margin-top: 16px;
        display: inline-block !important;
      }

      .btn-cancel {
        display: inline-block !important;
        font-weight: bold;
        color: $font-black !important;
        background-color: $font-white !important;
        font-size: 10px;
        padding: 5px 20px;
        border: 1px solid $color-grey1;
        margin: -15px 10px 10px 15px;
      }
    }

    .body {
      color: $font-white !important;
      background-color: $color-primary !important;
      border-radius: 0px 0px 10px 10px;
      display: inline-block !important;

      .body-1 {
        display: inline-block !important;
        margin: 10px 15px 10px 15px;
      }

      .image-trans-history {
        display: inline-block !important;
        margin-top: -40px;
        margin-right: 20px;
        margin-left: 20px;
      }

      .btn-action {
        font-weight: bold;
        color: $font-black !important;
        background-color: $font-white !important;
        font-size: 10px;
        padding: 5px 15px;
        margin: -35px 15px 0px 0px;
        border: 1px solid $color-grey1;
      }

      .btn-address {
        font-weight: bold;
        color: $font-white !important;
        background-color: $color-blue !important;
        font-size: 10px;
        padding: 5px 15px;
        margin: -15px 0px 0px 0px;
        border: 1px solid $color-blue;
      }
    }

    .width20 {
      width: 21%;
    }

    .width18 {
      width: 18%;
    }

    .width12 {
      width: 12%;
    }
  }

  .btn-order {
    font-weight: bold;
    color: $font-white !important;
    background-color: $color-primary;
    font-size: 10px;
    padding: 5px 20px;
    margin-right: 12px;
    border: none;
    width: 90px;
  }

  .trans-footer {
    margin: 20px 20px 20px 20px;

    .text-footer {
      display: inline-block !important;
      color: black !important;
    }

    .btn-prev-next {
      position: absolute;
      display: inline-block !important;
      justify-content: flex-end !important;
      right: 0 !important;

      .pagination-container {
        background-color: $font-white !important;
        border-radius: 20px;

        .pagination-item {
          color: #271e4a !important;
          font-weight: 500;

          &:hover {
            background-color: $color-primary;
            cursor: pointer;
            color: $font-white !important;
          }

          &.selected {
            background-color: $color-primary;
            color: $font-white !important;
          }

          .arrow {
            &::before {
              position: relative;
              content: "";
              display: inline-block;
              width: 0.4em;
              height: 0.4em;
              border-right: 0.12em solid $font-black !important;
              border-top: 0.12em solid $font-black !important;
            }

            &.left {
              transform: rotate(-135deg) translate(-50%);
            }

            &.right {
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }

  .btn-reset {
    display: inline-block !important;
    font-weight: bold;
    color: $font-black !important;
    background-color: $font-white !important;
    font-size: 10px;
    padding: 5px 20px;
    border: 1px solid $color-grey1;
    margin: 15px 10px 10px 52px;
  }

  .btn-prev {
    font-weight: bold;
    color: $font-white !important;
    font-size: 12px;
    padding: 5px 20px;
    margin-right: 12px;
    border: none;
    width: 120px;
  }

  table tr td {
    border-top: 1px solid transparent !important;
    border-bottom: 1px solid transparent !important;
  }
  strong {
    font-weight: 600;
    color: $font-black;
    line-height: 16px;
  }
  em {
    font-weight: 400;
    color: $font-black;
    font-style: normal;
    line-height: 16px;
  }
  h4 {
    font-weight: 400;
    font-size: 16px;
  }

  h5 {
    font-size: 10px;
    margin-top: 5px;
  }

  h3 {
    font-size: 14px;
    // font-weight: 600;
    // margin-top: 10px;
  }

  h6 {
    font-size: 10px;
  }

  h2 {
    font-size: 12px !important;
    color: #717171 !important;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
  }

  .circle-share {
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 50%;
    border: 1px solid $font-white;
    width: 40px;
    height: 40px;
    background: transparent !important;
    color: $color-blue;
    font-size: 15px;
    background: $font-white !important;
    border: none !important;
    font-weight: bold;
    margin-bottom: 20px;
    margin-left: 10px;
    text-align: center;
    box-shadow: 0px 5px 5px $color-grey2;
  }

  .circle-share:hover {
    cursor: pointer;
  }

  .card-header {
    padding: 0px !important;
  }

  .card-body {
    padding: 5px !important;
  }
}

.header-tab {
  margin: 8px 0px 12px 16px;
  .btn-tab {
    border-radius: 10px 10px 0px 0px !important;
    padding: 18px 20px 15px 20px;
    font-size: 10px;
    border: 1px solid $color-grey1 !important;
    font-weight: 600;
    color: $font-black !important;
    background-color: $font-white;
    height: 40px;
  }

  .btn-tab:hover {
    color: $font-white !important;
    background-color: $color-primary !important;
    cursor: pointer;
  }

  .active {
    background-color: $color-primary-lightness4 !important;
    color: $color-primary !important;
  }
}

.circle-share {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 50%;
  border: 1px solid $font-white;
  width: 30px;
  height: 30px;
  background: transparent !important;
  color: $color-blue;
  font-size: 15px;
  background: $font-white !important;
  border: none !important;
  font-weight: bold;
  margin-bottom: 20px;
  margin-left: 10px;
  text-align: center;
  box-shadow: 0px 5px 5px $color-grey2;
}

.btn-cetak {
  font-weight: bold;
  color: $color-primary !important;
  background-color: $font-white !important;
  font-size: 14px !important;
  padding: 8px 15px !important;
  border: 1px solid $color-primary !important;
  position: absolute;
  right: 0;
  margin: 0px 35px 0px 0px;
  border-radius: 30px;
}

.btn-cetak:disabled {
  background-color: $font-white !important;
  display: none;
  cursor: default !important;
}

.btn-download {
  font-weight: bold;
  color: $font-black !important;
  background-color: $font-white !important;
  font-size: 12px !important;
  padding: 5px 15px !important;
  border: 1px solid #cdcdcd !important;
  position: absolute;
  right: 0;
  margin: -3px 0px 10px 0px;
}

.body-card-custom {
  background: #00bd84;
  border-radius: 0px 0px 9px 9px;
}

.diagnosa-result {
  height: 52px;
  background: #e6f8f3;
  border-radius: 4px 4px 0px 0px;
  font-weight: 700;
  font-size: 16px;
  color: #424242;
}

.download-doc-card {
  border: 1px solid #c4c4c4;
  border-radius: 8px;
}

.doc-download-name {
  font-weight: 500;
  font-size: 16px;
  color: #00bd84;
}
