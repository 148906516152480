@import '../../../assets/scss/abstracts/variables.scss';

.content-payment-method {
  .circle-btn-prev {
    position: relative;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 50%;
    border: 3px solid $color-primary;
    width: 30px;
    height: 30px;
    background: transparent !important;
    z-index: 0;
    margin-right: 8px;
    .fa {
      color: $color-primary !important;
      font-size: 16px;
    }
  }

  .title-page {
    margin-top: 2px;
    font-size: 16px;
    font-weight: bold;
  }

  .nav-step {
    margin-bottom: 20px;
    .circle-btn-step {
      position: relative;
      -webkit-border-radius: 40px;
      -moz-border-radius: 40px;
      border-radius: 50%;
      border: 1px solid $font-white;
      width: 40px;
      height: 40px;
      background: transparent !important;
      z-index: 0;
      margin-right: 8px;
      color: $font-white;
      font-size: 16px;
      font-weight: normal;
    }

    .circle-btn-step:hover {
      cursor: default;
    }

    .activePage {
      background: $color-primary !important;
      border: none !important;
      font-weight: bold;
    }

    .label-step {
      font-size: 16px;
      font-weight: normal;
    }
  }

  .content-paymentMethod-step {
    background: $font-white !important;
    border-radius: 20px;
    min-width: 110%;
    padding: 30px;
    margin-top: 35px;
    margin-bottom: 35px;

    h1 {
      font-size: 24px !important;
      color: black !important;
      text-align: left;
      font-weight: 700;
      line-height: 24px;
    }

    h2 {
      margin-top: 10px;
      font-size: 14px !important;
      color: black !important;
      text-align: left;
      font-weight: 500;
      line-height: 24px;
    }

    h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      color: black !important;
      margin-top: 20px;
      margin-bottom: 5px;
    }

    .find-insurance {
      margin-top: 25px;
      width: 100%;
      .select-option {
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        .form-control {
          height: 40px !important;
          border: 1px solid $color-grey4 !important;
          padding-left: 15px !important;
          .fa {
            padding-right: 20px !important;
          }
        }
      }
    }

    .btn-prev-next {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 10px;

      .btn-prev {
        font-weight: bold;
        color: $font-white !important;
        font-size: 16px;
        padding: 8px 20px !important;
        border: none;
        min-width: 100%;
      }

      .inactive {
        color: $color-grey4 !important;
        background: $color-otp !important;
      }

      .inactive:hover {
        cursor: default;
      }
    }

    .choose-paymentType {
      margin-top: 30px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      .paymentType-card {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 30px;

        .form-control {
          border: 1px solid $color-grey4 !important;
          padding-left: 15px !important;
          height: 40px !important;
        }

        .errorRequired {
          border: 1px solid red !important;
        }
      }

      .errorRequired-upload {
        border-color: red !important;
      }

      h6 {
        font-weight: 500 !important;
        margin-top: 5px;
        font-size: 16px;
        color: red;
        font-weight: normal;
        text-align: left !important;
        margin-bottom: 15px !important;
        .fa {
          margin-right: 3px;
          margin-left: 10px;
        }
      }

      h4 {
        margin-top: 10px;
        text-align: right;
        color: $color-grey2 !important;
        font-size: 16px;
        margin-right: 5px;
      }

      h5 {
        margin-top: 10px;
        text-align: left;
        color: $color-grey2 !important;
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }

  .btn-add-dokumen {
    background: $color-blue !important;
    border: none;
    font-size: 16px;
    width: 170px;
    height: 40px;
    padding: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .btn-upload {
    display: inline-block !important;
    margin-left: 8px;
    background: $font-white;
    color: $color-grey3 !important;
    font-weight: lighter;
    width: 75px !important;
    height: 30px !important;
    font-size: 16px;
    padding: 5px 15px !important;
    border: 1px solid $color-grey3;
    border-radius: 8px !important;
  }

  .btn-upload:hover {
    cursor: pointer;
  }

  .btn-count-file {
    display: none;
    font-size: 16px !important;
    text-align: left;
    color: $color-primary;
    font-weight: lighter;
    background: transparent;
    border: none;
    height: 30px;
    margin-right: 450px;
    min-width: 200px;

    .view-doc {
      font-weight: bolder;
      margin-left: 5px;
    }

    .view-doc:hover {
      text-decoration: underline;
      color: $color-primary;
    }
  }

  .btn-count-file:hover {
    cursor: pointer;
  }
}

.content-payment-method-mobile {
  .circle-btn-prev {
    position: relative;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 50%;
    border: 3px solid $color-primary;
    width: 30px;
    height: 30px;
    background: transparent !important;
    z-index: 0;
    margin-right: 8px;
    .fa {
      color: $color-primary !important;
      font-size: 16px;
    }
  }

  .title-page {
    margin-top: 2px;
    font-size: 16px;
    font-weight: bold;
  }

  .step-mobile-title {
    font-weight: 300;
    font-size: 16px;
    line-height: 12px;
    text-align: center;
    color: #ffffff;
    margin-top: 30px;
  }

  .payment-method-mobile-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    margin-top: 10px;
  }

  .title1 {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    color: black !important;
  }

  .title2 {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: black !important;
    margin-top: 5px;
  }

  .content-paymentMethod-step {
    background: $font-white !important;
    border-radius: 20px;
    min-width: 110%;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 35px;

    h1 {
      font-size: 24px !important;
      color: black !important;
      text-align: left;
      font-weight: 700;
      line-height: 24px;
    }

    h2 {
      margin-top: 10px;
      font-size: 16px !important;
      color: black !important;
      text-align: left;
      font-weight: 500;
      line-height: 24px;
    }

    h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      color: black !important;
      margin-top: 20px;
      margin-bottom: 5px;
    }

    .find-insurance {
      margin-top: 25px;
      width: 100%;
      .select-option {
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        .form-control {
          height: 40px !important;
          border: 1px solid $color-grey4 !important;
          padding-left: 15px !important;
          .fa {
            padding-right: 20px !important;
          }
        }
      }
    }

    .btn-prev-next {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 10px;

      .btn-prev {
        font-weight: bold;
        color: $font-white !important;
        font-size: 16px;
        padding: 8px 20px !important;
        border: none;
        min-width: 100%;
      }

      .inactive {
        color: $color-grey4 !important;
        background: $color-otp !important;
      }

      .inactive:hover {
        cursor: default;
      }
    }

    .choose-paymentType {
      margin-top: 30px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      .paymentType-card {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 30px;

        .form-control {
          border: 1px solid $color-grey4 !important;
          padding-left: 15px !important;
          height: 40px !important;
        }

        .errorRequired {
          border: 1px solid red !important;
        }
      }

      .errorRequired-upload {
        border-color: red !important;
      }

      h6 {
        font-weight: 500 !important;
        margin-top: 5px;
        font-size: 16px;
        color: red;
        font-weight: lighter;
        text-align: left !important;
        margin-bottom: 15px !important;
        .fa {
          margin-right: 3px;
          margin-left: 10px;
        }
      }

      h4 {
        margin-top: 10px;
        text-align: right;
        color: $color-grey2 !important;
        font-size: 16px;
        margin-right: 5px;
      }

      h5 {
        margin-top: 10px;
        text-align: left;
        color: $color-grey2 !important;
        font-size: 9px;
        margin-left: 10px;
      }
    }
  }

  .btn-add-dokumen {
    background: $color-blue !important;
    border: none;
    font-size: 12px;
    width: 170px;
    height: 40px;
    padding: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .btn-upload {
    display: inline-block !important;
    margin-left: 8px;
    background: $font-white;
    color: $color-grey3 !important;
    font-weight: lighter;
    width: 75px !important;
    height: 30px !important;
    font-size: 12px;
    padding: 5px 15px !important;
    border: 1px solid $color-grey3;
    border-radius: 8px !important;
  }

  .btn-upload:hover {
    cursor: pointer;
  }

  .btn-count-file {
    display: none;
    font-size: 12px !important;
    text-align: left;
    color: $color-primary;
    font-weight: lighter;
    background: transparent;
    border: none;
    height: 30px;
    margin-right: 450px;
    min-width: 200px;

    .view-doc {
      font-weight: bolder;
      margin-left: 5px;
    }

    .view-doc:hover {
      text-decoration: underline;
      color: $color-primary;
    }
  }

  .btn-count-file:hover {
    cursor: pointer;
  }
}

.paymentMethodConfirmation {
  padding: 30px 10% 10px 10% !important;
}

.label-confirmation-left{
  display: inline;
  font-size: 16px;
  font-weight: normal
}

@media screen and (max-width: 900px) {
  .paymentMethodConfirmation {
    padding: 30px 0px 10px 0px !important;
  }
}
