.card-white,
.card-primary {
  background: #ffffff;
  border-radius: 20px;
  padding: 16px;
}

.card-primary {
  background: #e6f8f3;
}

.card-inner {
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  padding: 16px;
}
