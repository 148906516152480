@import '../../../assets/scss/abstracts/variables.scss';

.label-legend-calendar {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.content-schedule-doctor {
  .legend-container {
    margin-top: 10px;
    p {
      font-size: 16px;
      font-weight: 600;
      color: $font-black !important;
      margin-right: 10px;
    }
    .legend {
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 20px;
      margin-right: 5px;
    }
    .available {
      background-color: $color-blue2;
    }
    .full-waiting {
      background-color: $font-white;
      border: 1px solid $color-red1;
    }
    .full {
      background-color: $color-grey1;
    }
    .unavailable {
      background-color: $color-grey7;
    }
  }

  .circle-btn-prev {
    position: relative;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 50%;
    border: 3px solid $color-primary;
    width: 30px;
    height: 30px;
    background: transparent !important;
    z-index: 0;
    margin-right: 16px;
    .fa {
      color: $color-primary !important;
      font-size: 16px;
    }
  }
  .title-page {
    margin-top: 3px;
    font-size: 16px;
    font-weight: bold;
  }
  .appointment {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  h1 {
    font-size: 28px;
    font-weight: 600;
    line-height: 44px;
  }

  .app-nav {
    margin: auto;
    min-height: 268px;
    .nav-line {
      display: block;
      width: 120px;
      height: 8px;
      border-radius: 10px;
      margin: 0 2em 0 2em;
      background: $color-primary;
    }
    .nav-round {
      display: flex;
      width: 90px;
      height: 90px;
      border-radius: 60px;
      justify-content: center;
      align-items: center;
      color: $font-white !important;
      font-size: 50px;
      font-weight: 600;
      margin-top: 1em;
    }
    .nav-text {
      color: $font-white;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      margin-top: 1em;
    }
    .activePage {
      background: $color-primary;
    }
  }

  .nav-step {
    margin-bottom: 20px;
    .circle-btn-step {
      position: relative;
      -webkit-border-radius: 40px;
      -moz-border-radius: 40px;
      border-radius: 50%;
      border: 1px solid $font-white;
      width: 40px;
      height: 40px;
      background: transparent !important;
      z-index: 0;
      margin-right: 8px;
      color: $font-white;
      font-size: 16px;
      font-weight: normal;
    }

    .circle-btn-step:hover {
      cursor: default;
    }

    .activePage {
      background: $color-primary !important;
      border: none !important;
      font-weight: bold;
    }

    .label-step {
      font-size: 16px;
      font-weight: normal;
    }
  }

  .init-app-card {
    background-color: $font-white;
    border-radius: 20px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border: 1px solid #e7e7e7;
    padding-left: 0.5em;
    padding-right: 0.5em;
    height: 380px;
    font-size: 16px;

    .card-img {
      width: 64px;
      height: 64px;
      border-radius: 20px;
      img {
        width: 64px;
        height: 64px;
        border-radius: 20px;
      }
    }
    .border-bottom {
      border-bottom: 2px solid #ededed;
    }

    .container-BPJS {
      border-top: 1px solid $color-grey5;
      padding-top: 18px;
      padding-bottom: 18px;
    }

    .time-slot {
      max-height: 190px;
      overflow-y: auto;
    }

    .hidden {
      position: absolute;
      visibility: hidden;
      opacity: 0;
    }

    .hidden + label {
      background-color: $font-white;
      border: 1px solid $color-blue;
      width: 100%;
      text-align: center;
      color: $color-blue !important;
      border-radius: 50px;
      font-style: normal;
      padding: 3px 10px 3px 10px;
      cursor: pointer;
      margin: 5px 0 0 0;
      font-size: 16px;
    }

    .hidden:checked + label {
      background-color: $color-blue !important;
      color: $font-white !important;
      font-style: normal;
    }

    .hidden:disabled + label {
      background: $color-grey7 !important;
      color: $font-white !important;
      border-color: $color-grey7;
      cursor: not-allowed;
    }

    .fullbook {
      position: absolute;
      visibility: hidden;
      opacity: 0;
    }

    .fullbook + label {
      background-color: $color-red1;
      border: 1px solid $color-red1;
      width: 100%;
      text-align: center;
      color: $font-white !important;
      border-radius: 50px;
      font-style: normal;
      padding: 3px 10px 3px 10px;
      cursor: pointer;
      margin: 5px 0 0 0;
      font-size: 16px;
    }

    .fullbook:checked + label {
      background-color: $color-grey7 !important;
      color: $font-white !important;
      font-style: normal;
    }
  }

  .app-card {
    background-color: $font-white;
    border-radius: 20px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border: 1px solid #e7e7e7;
    padding-left: 0.5em;
    padding-right: 0.5em;
    max-height: 393px;
    .card-img {
      width: 64px;
      height: 64px;
      border-radius: 20px;
      img {
        width: 64px;
        height: 64px;
        border-radius: 20px;
      }
    }
    .border-bottom {
      border-bottom: 2px solid #ededed;
    }

    .container-BPJS {
      border-top: 1px solid $color-grey5;
      padding-top: 18px;
      padding-bottom: 18px;
    }

    .time-slot {
      max-height: 190px;
      overflow-y: auto;
    }

    .hidden {
      position: absolute;
      visibility: hidden;
      opacity: 0;
    }

    input[type='radio'] + label {
      background-color: $font-white;
      border: 1px solid $color-blue;
      width: 100%;
      text-align: center;
      color: $color-blue !important;
      border-radius: 50px;
      font-style: normal;
      padding: 3px 10px 3px 10px;
      cursor: pointer;
      margin: 5px 0 0 0;
      font-size: 16px;
    }

    input[type='radio']:checked + label {
      background-color: $color-blue !important;
      color: $font-white !important;
      font-style: normal;
      font-size: 16px;
    }
  }

  ul {
    list-style-type: none;
  }

  .content-doctorSchedule-step {
    background: $font-white !important;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 35px;

    h1 {
      font-size: 24px !important;
      color: $font-black !important;
      text-align: left;
      line-height: 24px !important;
      font-weight: 700 !important;
      margin-bottom: 0.4em;
      margin-top: 0.4em;
    }

    h2 {
      font-size: 14px !important;
      color: $font-black !important;
      text-align: left;
      line-height: 24px !important;
      font-weight: 500 !important;
      margin-bottom: 2em;
    }
  }

  .card-txt {
    width: 100%;
    font-size: 16px;
    strong {
      font-size: 16px;
      font-weight: 800;
    }
    p {
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: $font-black;
      margin-left: 1em;
    }
    em {
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-size: 16px;
    }
  }
}
.react-calendar {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.activeDate:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(
    .-selected
  ) {
  background-color: $color-blue2 !important;
}

.waitingList:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(
    .-selected
  ) {
  background-color: $font-white !important;
  border: 2px solid $color-red1;
}

.fullBooked:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(
    .-selected
  ) {
  color: $font-white !important;
  background-color: $color-grey1 !important;
  pointer-events: none !important;
}

footer {
  p {
    position: absolute;
  }
}

.content-schedule-doctor-mobile {
  .legend-container {
    margin-top: 10px;
    p {
      font-size: 16px;
      font-weight: 600;
      color: $font-black !important;
      margin-right: 10px;
    }
    .legend {
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 20px;
      margin-right: 5px;
    }
    .available {
      background-color: $color-blue2;
    }
    .full-waiting {
      background-color: $font-white;
      border: 1px solid $color-red1;
    }
    .full {
      background-color: $color-grey1;
    }
    .unavailable {
      background-color: $color-grey7;
    }
  }

  .circle-btn-prev {
    position: relative;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 50%;
    border: 3px solid $color-primary;
    width: 30px;
    height: 30px;
    background: transparent !important;
    z-index: 0;
    margin-right: 8px;
    .fa {
      color: $color-primary !important;
      font-size: 14px;
    }
  }

  .title-page {
    margin-top: 2px;
    font-size: 18px;
    font-weight: bold;
  }

  .step-mobile-title {
    font-weight: 300;
    font-size: 16px;
    line-height: 12px;
    text-align: center;
    color: #ffffff;
  }

  .doctor-schedule-mobile-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    margin-top: 10px;
  }

  .title1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    color: black !important;
  }

  .title2,
  .mobile-title2 {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: black !important;
    margin-top: 5px;
  }

  .content-doctorSchedule-step {
    background: $font-white !important;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 35px;

    h1 {
      font-size: 24 !important;
      color: $font-black !important;
      text-align: left;
      line-height: 24px !important;
      font-weight: 700 !important;
      margin-bottom: 0.4em;
      margin-top: 0.4em;
    }

    h2 {
      font-size: 16px !important;
      color: $font-black !important;
      text-align: left;
      line-height: 24px !important;
      font-weight: 500 !important;
      margin-bottom: 2em;
    }
  }

  .btn-next-prev {
    height: 32px;
    width: 100%;
    border-radius: 50px;
    padding: 0px 30px 0px 30px !important;
    font-size: 16px !important;
  }

  .app-card {
    background-color: $font-white;
    border-radius: 20px;
    padding-top: 0.3em;
    border: 1px solid #e7e7e7;
    padding-left: 0.3em;
    padding-right: 0.3em;
    max-height: 373px;

    .card-img {
      width: 50px;
      height: 50px;
      border-radius: 10px;
      img {
        width: 50px;
        height: 50px;
        border-radius: 10px;
      }
    }

    .card-txt {
      width: 100%;
      strong {
        font-size: 16px;
        font-weight: 800;
      }
      p {
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: $font-black;
        margin-left: 1em;
      }
      em {
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
      }
    }

    .btn-change-blue {
      color: $font-white;
      padding: 3px 10px 3px 10px;
      border-radius: 50px;
      font-weight: 700;
      font-size: 16px;
      border-color: $color-blue;
      background: $color-blue;
      margin-left: 30px;
    }

    .border-bottom {
      border-bottom: 2px solid #ededed;
    }

    .container-BPJS {
      border-top: 1px solid $color-grey5;
      padding-top: 18px;
      padding-bottom: 18px;
    }

    .time-slot {
      max-height: 190px;
      overflow-y: auto;
    }

    .hidden {
      position: absolute;
      visibility: hidden;
      opacity: 0;
    }

    input[type='radio'] + label {
      background-color: $font-white;
      border: 1px solid $color-blue;
      width: 100%;
      text-align: center;
      color: $color-blue !important;
      border-radius: 50px;
      font-style: normal;
      padding: 3px 10px 3px 10px;
      cursor: pointer;
      margin: 5px 0 0 0;
      font-size: 16px;
    }

    input[type='radio']:checked + label {
      background-color: $color-blue !important;
      color: $font-white !important;
      font-style: normal;
      font-size: 16px;
    }
  }

  .appointment {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .app-nav {
    margin: auto;
    min-height: 268px;
    .nav-line {
      display: block;
      width: 120px;
      height: 8px;
      border-radius: 10px;
      margin: 0 2em 0 2em;
      background: $color-primary;
    }
    .nav-round {
      display: flex;
      width: 90px;
      height: 90px;
      border-radius: 60px;
      justify-content: center;
      align-items: center;
      color: $font-white !important;
      font-size: 50px;
      font-weight: 600;
      margin-top: 1em;
    }
    .nav-text {
      color: $font-white;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      margin-top: 1em;
    }
    .activePage {
      background: $color-primary;
    }
  }

  .nav-step {
    margin-bottom: 20px;
    .circle-btn-step {
      position: relative;
      -webkit-border-radius: 40px;
      -moz-border-radius: 40px;
      border-radius: 50%;
      border: 1px solid $font-white;
      width: 40px;
      height: 40px;
      background: transparent !important;
      z-index: 0;
      margin-right: 8px;
      color: $font-white;
      font-size: 16px;
      font-weight: normal;
    }

    .circle-btn-step:hover {
      cursor: default;
    }

    .activePage {
      background: $color-primary !important;
      border: none !important;
      font-weight: bold;
    }

    .label-step {
      font-size: 16px;
      font-weight: normal;
    }
  }

  .init-app-card {
    background-color: $font-white;
    border-radius: 20px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border: 1px solid #e7e7e7;
    padding-left: 0.5em;
    padding-right: 0.5em;
    height: 380px;

    .card-img {
      width: 64px;
      height: 64px;
      border-radius: 20px;
      img {
        width: 64px;
        height: 64px;
        border-radius: 20px;
      }
    }
    .border-bottom {
      border-bottom: 2px solid #ededed;
    }

    .container-BPJS {
      border-top: 1px solid $color-grey5;
      padding-top: 18px;
      padding-bottom: 18px;
    }

    .time-slot {
      max-height: 190px;
      overflow-y: auto;
    }

    .hidden {
      position: absolute;
      visibility: hidden;
      opacity: 0;
    }

    .hidden + label {
      background-color: $font-white;
      border: 1px solid $color-blue;
      width: 100%;
      text-align: center;
      color: $color-blue !important;
      border-radius: 50px;
      font-style: normal;
      padding: 3px 10px 3px 10px;
      cursor: pointer;
      margin: 5px 0 0 0;
      font-size: 16px;
    }

    .hidden:checked + label {
      background-color: $color-blue !important;
      color: $font-white !important;
      font-style: normal;
    }

    .hidden:disabled + label {
      background: $color-grey7 !important;
      color: $font-white !important;
      border-color: $color-grey7;
      cursor: not-allowed;
    }

    .fullbook {
      position: absolute;
      visibility: hidden;
      opacity: 0;
    }

    .fullbook + label {
      background-color: $color-red1;
      border: 1px solid $color-red1;
      width: 100%;
      text-align: center;
      color: $font-white !important;
      border-radius: 50px;
      font-style: normal;
      padding: 3px 10px 3px 10px;
      cursor: pointer;
      margin: 5px 0 0 0;
      font-size: 16px;
    }

    .fullbook:checked + label {
      background-color: $color-grey7 !important;
      color: $font-white !important;
      font-style: normal;
    }
  }

  ul {
    list-style-type: none;
  }
}

/* Mobile View */
.mobile-doctor-name,
.mobile-doctor-specialization {
  font-size: 20px;
  color: #424242;
}

.mobile-doctor-specialization {
  font-size: 16px;
}

.mobile-legend-label {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.mobile-title-card {
  font-weight: 700;
  font-size: 18px;
  color: #343434;
}

.mobile-radio-label {
  font-size: 18px;
}

.check-list {
  margin-top:1em;
  padding-left: 1.2rem;

}

.check-list li {
  position: relative;
  list-style-type: none;
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
  color: #343A40;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
}

.check-list li:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: -2px;
    width: 5px;
    height: 11px;
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: #00a8a8;
    transform-origin: bottom left;
    transform: rotate(45deg);
}
