@import '../../../assets/scss/abstracts/variables.scss';

span {
  font-weight: 500;
}

.errorRequired {
  border: 1px solid red !important;
}

.span-error {
  font-size: 16px;
  color: red;
  font-weight: lighter;

  .fa {
    margin-right: 3px;
    margin-left: 10px;
  }
}

.content-patient {
  .circle-btn-prev {
    position: relative;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 50%;
    border: 3px solid $color-primary;
    width: 30px;
    height: 30px;
    background: transparent !important;
    z-index: 0;
    margin-right: 8px;
    .fa {
      color: $color-primary !important;
      font-size: 16px;
    }
  }

  .title-page {
    margin-top: 3px;
    font-size: 16px;
    font-weight: bold;
  }

  .nav-step {
    margin-bottom: 20px;
    .circle-btn-step {
      position: relative;
      -webkit-border-radius: 40px;
      -moz-border-radius: 40px;
      border-radius: 50%;
      border: 1px solid $font-white;
      width: 40px;
      height: 40px;
      background: transparent !important;
      z-index: 0;
      margin-right: 8px;
      color: $font-white;
      font-size: 16px;
      font-weight: normal;
    }

    .circle-btn-step:hover {
      cursor: default;
    }

    .activePage {
      background: $color-primary !important;
      border: none !important;
      font-weight: bold;
    }

    .label-step {
      font-size: 16px;
      font-weight: normal;
    }
  }

  .content-patient-step {
    background: $font-white !important;
    border-radius: 20px;
    min-width: 115%;
    padding: 30px;
    margin-top: 35px;
    margin-bottom: 35px;

    h1 {
      font-size: 24px !important;
      color: black !important;
      text-align: left;
      font-weight: 700;
      line-height: 24px;
    }

    h2 {
      margin-top: 10px;
      font-size: 16px !important;
      color: black !important;
      text-align: left;
      font-weight: 500;
      line-height: 24px;
    }

    h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      color: black !important;
      margin-bottom: 0.5em;
    }

    .find-profile {
      // margin-top: 2px;
      width: 100%;
      .select-option {
        width: 100%;
        display: inline-block !important;
        .form-control {
          height: 40px !important;
          border: 1px solid $color-grey2 !important;
          padding-left: 15px !important;
          .fa {
            padding-right: 20px !important;
          }
        }

        .form-control:focus {
          border-color: $color-primary !important;
        }
      }

      .btn-add-patient {
        display: inline-block !important;
      }
    }

    .btn-prev-next {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 10px;

      .btn-prev {
        font-weight: bold;
        color: $font-white !important;
        font-size: 16px;
        padding: 8px 20px !important;
        border: none;
        min-width: 100%;
      }

      .inactive {
        color: $color-grey4 !important;
        background: $color-otp !important;
      }

      .inactive:hover {
        cursor: default;
      }
    }

    .choose-patient {
      margin-top: 30px;
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      .profile-patient {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        .profile-card {
          background-color: $font-white;
          border-radius: 20px;
          margin-bottom: 25px;
          padding-top: 1em;
          padding-bottom: 1em;
          border: 1px solid $color-grey2 !important;
          color: $font-black;
          text-align: left;
          padding-left: 1em;

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: black !important;
            margin: 0.3em 1.5em 0.3em 1.5em;
          }
        }

        textarea {
          font-size: 16px !important;
          padding: 15px;
          border: 1px solid $color-grey2 !important;
        }

        .select-option {
          margin-top: 10px;
          width: 80%;
          display: inline-block !important;
          .form-control {
            height: 40px !important;
            border: 1px solid $color-grey2 !important;
            padding-left: 15px !important;
            .fa {
              padding-right: 20px !important;
            }
          }

          .form-control:focus {
            border-color: $color-primary !important;
          }
        }

        .label-file-upload {
          display: inline-block !important;
          text-align: center;
          background: $color-blue !important;
          color: $font-white !important;
          font-size: 16px;
          width: 130px;
          height: 40px;
          padding: 10px;
          border-radius: 25px;
          margin-left: 20px;
        }
      }

      .btn-count-file {
        display: none;
        font-size: 16px !important;
        text-align: left;
        color: $color-primary;
        font-weight: normal;
        background: transparent;
        border: none;
        height: 60px;
        margin-right: 480px;
        min-width: 200px;

        .view-doc {
          font-weight: bolder;
          margin-left: 5px;
        }

        .view-doc:hover {
          text-decoration: underline;
          color: $color-primary;
        }
      }

      .btn-count-file:hover {
        cursor: pointer;
      }
    }

    h4 {
      margin-top: 5px;
      text-align: right;
      color: $color-grey2 !important;
      font-size: 16px;
      margin-right: 5px;
    }
  }

  .btn-upload {
    display: inline-block !important;
    margin-left: 8px;
    background: $font-white;
    color: $color-grey3 !important;
    font-weight: normal;
    width: 75px !important;
    height: 30px !important;
    font-size: 16px;
    padding: 5px 15px !important;
    border: 1px solid $color-grey3;
    border-radius: 8px !important;
  }

  .btn-upload:hover {
    cursor: pointer;
  }

  .btn-add-dokumen {
    background: $color-blue !important;
    border: none;
    font-size: 16px;
    width: 170px;
    height: 40px;
    padding: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.label-file-upload {
  text-align: center;
  background: $color-blue !important;
  color: $font-white !important;
  font-size: 16px;
  width: 120px;
  height: 30px;
  padding: 5px;
  border-radius: 25px;
  margin-left: 20px;
}

.label-file-upload:hover {
  cursor: pointer;
}

.content-patient-mobile {
  .circle-btn-prev {
    position: relative;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 50%;
    border: 3px solid $color-primary;
    width: 30px;
    height: 30px;
    background: transparent !important;
    z-index: 0;
    margin-right: 8px;
    .fa {
      color: $color-primary !important;
      font-size: 16px;
    }
  }

  .title-page {
    margin-top: 3px;
    font-size: 16px;
    font-weight: bold;
  }

  .step-mobile-title {
    font-weight: 300;
    font-size: 16px;
    line-height: 12px;
    text-align: center;
    color: #ffffff;
    margin-top: 30px;
  }

  .patient-mobile-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    margin-top: 10px;
  }

  .title1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    color: black !important;
  }

  .title2 {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: black !important;
    margin-top: 5px;
  }

  .content-patient-step {
    background: $font-white !important;
    border-radius: 20px;
    min-width: 110%;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 35px;

    h1 {
      font-size: 24px !important;
      color: black !important;
      text-align: left;
      font-weight: 700;
      line-height: 24px;
    }

    h2 {
      margin-top: 10px;
      font-size: 16px !important;
      color: black !important;
      text-align: left;
      font-weight: 500;
      line-height: 24px;
    }

    h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      color: black !important;
      margin-bottom: 0.5em;
    }

    .find-profile {
      margin-top: 25px;
      width: 100%;
      .select-option {
        width: 100%;
        .form-control {
          height: 40px !important;
          border: 1px solid $color-grey2 !important;
          padding-left: 15px !important;
          .fa {
            padding-right: 20px !important;
          }
        }

        .form-control:focus {
          border-color: $color-primary !important;
        }
      }

      .btn-add-patient {
        background: $color-blue;
        border: none;
        font-size: 16px;
        width: 100%;
        height: 40px;
        padding: 3px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .btn-prev-next {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 10px;

      .btn-prev {
        font-weight: bold;
        color: $font-white !important;
        font-size: 16px;
        padding: 8px 20px !important;
        border: none;
        min-width: 100%;
      }

      .inactive {
        color: $color-grey4 !important;
        background: $color-otp !important;
      }

      .inactive:hover {
        cursor: default;
      }
    }

    .choose-patient {
      margin-top: 30px;
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      .profile-patient {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        .profile-card {
          background-color: $font-white;
          border-radius: 20px;
          margin-bottom: 25px;
          padding-top: 1em;
          padding-bottom: 1em;
          border: 1px solid $color-grey2 !important;

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: black !important;
            margin: 0.3em 1.5em 0.3em 1.5em;
          }
        }

        textarea {
          font-size: 16px !important;
          padding: 15px;
          border: 1px solid $color-grey2 !important;
        }

        .select-option {
          margin-top: 10px;
          width: 80%;
          display: inline-block !important;
          .form-control {
            height: 40px !important;
            border: 1px solid $color-grey2 !important;
            padding-left: 15px !important;
            .fa {
              padding-right: 20px !important;
            }
          }

          .form-control:focus {
            border-color: $color-primary !important;
          }
        }

        .label-file-upload {
          display: inline-block !important;
          text-align: center;
          background: $color-blue !important;
          color: $font-white !important;
          font-size: 16px;
          width: 130px;
          height: 40px;
          padding: 10px;
          border-radius: 25px;
          margin-left: 20px;
        }
      }

      .btn-count-file {
        display: none;
        font-size: 16px !important;
        text-align: left;
        color: $color-primary;
        font-weight: normal;
        background: transparent;
        border: none;
        height: 60px;
        margin-right: 480px;
        min-width: 200px;

        .view-doc {
          font-weight: bolder;
          margin-left: 5px;
        }

        .view-doc:hover {
          text-decoration: underline;
          color: $color-primary;
        }
      }

      .btn-count-file:hover {
        cursor: pointer;
      }
    }

    h4 {
      margin-top: 5px;
      text-align: right;
      color: $color-grey2 !important;
      font-size: 16px;
      margin-right: 5px;
    }
  }

  .btn-upload {
    display: inline-block !important;
    margin-left: 8px;
    background: $font-white;
    color: $color-grey3 !important;
    font-weight: normal;
    width: 75px !important;
    height: 30px !important;
    font-size: 16px;
    padding: 5px 15px !important;
    border: 1px solid $color-grey3;
    border-radius: 8px !important;
  }

  .btn-upload:hover {
    cursor: pointer;
  }

  .btn-add-dokumen {
    background: $color-blue !important;
    border: none;
    font-size: 16px;
    width: 170px;
    height: 40px;
    padding: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.container-modal {
  overflow-y: auto;
  max-height: 200px;
}
@media screen and (max-height: 640px) {
  .container-modal {
    max-height: 350px;
  }
}
@media screen and (min-height: 667px) {
  .container-modal {
    max-height: 370px;
  }
}

@media screen and (min-height: 700px) {
  .container-modal {
    max-height: 500px;
  }
}
@media screen and (min-height: 800px) {
  .container-modal {
    max-height: 600px;
  }
}
