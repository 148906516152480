.label-input-text {
  font-weight: 600;
  font-size: 16px;
  color: #343434;
  margin-bottom: 8px;
}

.warning-message-input {
  font-weight: 400;
  font-size: 13px;
  color: #ff0000;
  margin-left: 10px;
}

.form-input-text,
.form-input-text-warning {
  min-height: 40px;
  border: 1px solid #cccccc;
  border-radius: 50px;
  font-weight: 400;
  color: #343434;
  padding: 0 10px;
  font-size: 16px;
  padding-left: 17px;
  padding-right: 17px;
}

.form-input-text-warning {
  border: 1px solid #ff0000;
}

.text-size {
  font-weight: 400;
  font-size: 16px;
  color: #cccccc;
}

.font12 {
  font-size: 12px !important;
}
.font8 {
  font-size: 8px !important;
}

.container-field {
  position: relative;
}

.input-icon-right {
  padding-right: 40px;
}

.input-icon-left {
  padding-left: 40px;
}

.left-icon {
  position: absolute;
  left: 0;
  top: 0;
  padding: 10px 10px;
}

.right-icon {
  position: absolute;
  right: 10px;
  top: 0;
  padding: 10px 10px;
}
