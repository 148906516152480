.partner-us-card {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #bbbaba;
  min-height: 100px;
}

.partner-us-image {
  height: auto;
  width: 150px;
  margin-bottom: 8px;
}

.overflow-line-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

.partner-iframe-gmap {
  height: 250px;
  width: 100%;
  border: none;
  margin-bottom: 16px;
}
