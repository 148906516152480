h1{
    font-size: 64px;
    font-weight: bold;
    line-height: 76px;
    
}
h2{
    font-size: 36px;
    font-weight: bold;
}
h3{
    font-size: 28px;
    font-weight: bold;
}
h4{
    font-size: 20px;
    font-weight: bold;
}

