@import '../../../assets/scss/abstracts/variables.scss';

.content-orderDone {
  margin-bottom: 90px;
  width: 100%;
  background: #fff;
  border-radius: 20px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);

  .background-utama {
    background-image: linear-gradient($color-primary, $color-primary);
    clip-path: ellipse(56% 40% at 50% 15%);
    border-radius: 15px;
    height: 750px;
    text-align: center;

    span {
      font-size: 24px !important;
      font-weight: 700;
      line-height: 34px;
      color: $font-white !important;
      margin-top: 5px;
    }
  }

  .order-card {
    margin-top: -480px;
    clip-path: ellipse(100% 100% at 50% 15%);
    background-color: $font-white;
    box-shadow: 5px 5px 5px #e7e7e7;
    border: 1px solid #e7e7e7;
    border-radius: 20px;
    margin-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 2.5em;
    padding-bottom: 2em;
    color: $font-black;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;

    .col1 {
      max-width: 50%;
      padding: 5px 5px 5px 60px;
      line-height: 20px;
    }

    .col2 {
      max-width: 50%;
      font-weight: bold;
      padding: 5px 50px 5px 5px;
      line-height: 20px;
    }
  }

  .btn-done {
    width: 100%;
    font-weight: bold;
    color: $font-white !important;
    font-size: 16px;
    padding: 8px 20px !important;
    border: none;
    margin-top: 60px;
  }

  h2 {
    font-size: 16px !important;
    color: #717171 !important;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
  }

  h3 {
    font-size: 16px !important;
    color: black !important;
    font-weight: 350;
    line-height: 20px;
    text-align: center;
  }

  .circle-share {
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 50%;
    border: 1px solid $font-white;
    width: 60px;
    height: 60px;
    background: transparent !important;
    // color: $color-blue;
    color: $color-primary;
    font-size: 16px;
    background: $font-white !important;
    border: none !important;
    font-weight: bold;
    margin-bottom: 20px;
    margin-left: 10px;
    text-align: center;
    box-shadow: 0px 5px 5px $color-grey2;
  }

  .circle-share:hover {
    cursor: pointer;
  }

  .fixed-height {
    height: 40px;
  }

  .msg-info {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
  }

  @media screen and (max-width: 767px) {
    .image {
      height: 110px;
      width: 110px;
    }

    .background-utama {
      background-image: linear-gradient($color-primary, $color-primary);
      clip-path: ellipse(56% 40% at 50% 15%);
      border-radius: 10px;
      height: 580px;
      text-align: center;

      span {
        font-size: 16px !important;
        font-weight: 700;
        line-height: 24px;
        color: $font-white !important;
        margin-top: 0px;
      }
    }

    .order-card {
      margin-top: -350px;
      clip-path: ellipse(100% 100% at 50% 15%);
      background-color: $font-white;
      box-shadow: 5px 5px 5px #e7e7e7;
      border: 1px solid #e7e7e7;
      border-radius: 20px;
      margin-bottom: 30px;
      width: 95%;
      margin-left: 9px;
      padding-top: 2em;
      padding-bottom: 2em;
      font-size: 16px;
      font-weight: 500;
      line-height: 13px;
      letter-spacing: 0em;
      text-align: left;
      color: $font-black;

      .col1 {
        max-width: 42%;
        padding: 10px 5px 5px 35px;
      }

      .col2 {
        max-width: 58%;
        font-weight: bold;
        padding: 10px 15px 5px 5px;
      }
    }

    .msg-info {
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;
      text-align: center;
      font-size: 16px;
    }
  }
}
