@import "../../../assets/scss/abstracts/variables.scss";

.content-checkin{
    width: 100%;
    background: #fff;
    border-radius: 20px; 
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);

    .background-utama{
        background-image: linear-gradient($color-primary, $color-primary);
        clip-path: ellipse(56% 40% at 50% 15%);
        border-radius: 15px;
        height: 550px;
        text-align: center;

        span{
            font-size: 22px !important;
            font-weight: 600;
            line-height: 30px;
            color: $font-black !important;
            margin-top: 50px;
            margin-left: 100px;
            margin-right: 100px;
        }
    }

    .order-card{
        margin-top: -380px;
        clip-path: ellipse(100% 100% at 50% 15%);
        background-color: $font-white;
        box-shadow: 5px 5px 5px #E7E7E7;
        border: 1px solid #E7E7E7;
        border-radius: 20px;
        margin-right: 240px;
        margin-left: 240px;
        padding-top: 2.5em;
        padding-bottom: 2em;
        color: $font-black;
        font-size: 16px;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;

        .col1{
            max-width: 42%;
            padding: 5px 5px 5px 60px;
        }

        .col2{
            max-width: 58%;
            font-weight: bold;
            padding: 5px 50px 5px 5px;
        }
    }

    .info-checkin{
        color: black; 
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        word-wrap: break-word;
        padding: 30px 30px 0px 30px;
    }
    
    .btn-done{
        width: 100%;
        font-weight: bold;
        color: $font-white !important;
        font-size: 12px;
        padding: 8px 20px !important;
        border: none;
        margin-top: 10px;
    }

    .btn-cancel{
        width: 100%;
        font-weight: bold;
        color: $color-primary !important;
        border: 1px solid $color-primary !important;
        background: $font-white !important;
        font-size: 12px;
        padding: 8px 20px !important;
        border: none;
        margin-top: 10px;
    }

    @media screen and (max-width: 767px) {
        .background-utama{
            background-image: linear-gradient($color-primary, $color-primary);
            clip-path: ellipse(56% 40% at 50% 15%);
            border-radius: 10px;
            height: 450px;
            text-align: center;
    
            span{
                font-size: 18px !important;
                font-weight: 600;
                line-height: 24px;
                color: $font-white !important;
                margin-top: 30px;
                margin-left: 0px;
                margin-right: 0px;
            }
        }
    
        .order-card{
            margin-top: -285px;
            clip-path: ellipse(100% 100% at 50% 15%);
            background-color: $font-white;
            box-shadow: 5px 5px 5px #E7E7E7;
            border: 1px solid #E7E7E7;
            border-radius: 20px;
            width: 95%;
            margin-left: 9px;
            padding-top: 2em;
            padding-bottom: 2em;
            font-size: 16px;
            font-weight: 500;
            line-height: 13px;
            letter-spacing: 0em;
            text-align: left;
            color: $font-black;

            .col1{
                max-width: 42%;
                padding: 10px 5px 5px 35px;
            }

            .col2{
                max-width: 58%;
                font-weight: bold;
                padding: 10px 15px 5px 5px;
            }
        }

        .info-checkin{
            color: black; 
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            word-wrap: break-word;
            padding: 20px 20px 0px 20px;
        }
    }
}