@import "../../../assets/scss/abstracts/variables.scss";

.content-TnC{
    margin-left: 50px !important;

    .circle-btn-prev{
        position: relative;
        -webkit-border-radius:30px;
        -moz-border-radius:30px;
        border-radius:50%;
        border: 3px solid $color-primary;
        width:30px;
        height:30px;
        background: transparent !important;
        z-index: 0;
        margin-right: 8px;
        margin-bottom: 25px;
        .fa{
            color: $color-primary !important;
            font-size: 14px;
        }
    }

    .title-page{
        font-size: 16px;
        font-weight: bold;
    }

    h1{
        font-size: 24px !important;
        font-weight: 700;
        line-height: 24px;
        color: black;
    }

    h2{
        font-size: 12px !important;
        color: #717171 !important;
        font-weight: 600;
        line-height: 20px;
        text-align: center;
    }

    h3{
        font-size: 12px !important;
        color: black !important;
        font-weight: lighter;
        line-height: 20px;
        text-align: left;
    }

    h4{
        font-size: 12px !important;
        color: black !important;
        font-weight: lighter;
        line-height: 20px;
        text-align: center;
    }

    .background-utama{
        background: $font-white;
        border-radius: 15px;
    }

    .circle-tnc{
        background: transparent !important;
        color: $color-grey1;
        font-size: 180px;
        border: none !important;
        font-weight: bold;
        margin-top: 30px;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .circle-tnc:hover{
        cursor: default;
    }

    .row-tnc{
        padding: 40px 50px 60px 50px;
        font-size: 12px !important;
        color: black;
        .col-num{
            max-width: 3%;
            margin-top: 10px;
        }
        
        .col-desc{
            max-width: 97%;
            margin-top: 10px;
        }
    }

    .header-tnc{
        padding: 50px 120px 20px 120px;
        font-weight: 700;
        text-align: center;
        color: black !important;
    }
}

