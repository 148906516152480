
#content-detail-invoice {

    color: black;

    .table-wrapper {
        min-height: auto !important;
    }
    .header-detail-invoice{
        border-bottom: 1px solid #E7E7E7;
        margin-bottom: 8px;
        .name-clinic{
            font-size: 32px;
        }
        .detail-invoice-title {
            font-size: 36px;
        }
        .header-detail-invoice-title{
            .detail-invoice-title-name{
                font-size: 20px;
                font-weight: bold;
            }
        }
    }

    .table-reference{

        border-bottom: 1px solid #dedede;

        th{
            border-top: 1px solid #dedede;
            border-bottom: 1px solid #dedede;
            padding: 16px 4px;
            
        }
        td{
            border-top: none;
            border-bottom: none;
            padding: 16px 4px;
        }

    }

    .table-item{

        th{
            border-top: none;
            border-bottom: none;
            padding: 16px 4px;
            
        }
        td{
            border-top: none;
            border-bottom: none;
            padding: 16px 4px;
        }

        .item-total{
            border-top: 1px solid #dedede;
        }
    }
    .detail-payment-list-info{
        padding-left: 0px;
    }

}

#footer-detail-invoice {
    font-size: 18px;
    margin-bottom: 10px;
}