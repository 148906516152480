@import 'scss/abstracts/abstracts-dir.scss';
@import 'scss/base/base-dir.scss';
@import 'scss/components/components-dir.scss';
@import 'scss/vendor/vendor-dir.scss';

html,
body {
  // background: -webkit-gradient(
  //     linear,
  //     left top,
  //     left bottom,
  //     from($background-color-primary),
  //     to($background-color-secondary)
  //   )
  //   fixed;
  background-color:#011C70 ;
  font-family: 'Montserrat' !important;
  color: $font-white;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  font-weight: 400;
}
p {
  font-family: 'Montserrat' !important;
}

@media (max-width: 767px) {
  .hidden-total {
    display: none;
  }
}

button[aria-expanded='true'] .fa-angle-down {
  transition: 0.3s transform ease-in-out;
  transform: rotate(180deg);
}

button[aria-expanded='false'] .fa-angle-down {
  transition: 0.3s transform ease-in-out;
  transform: rotate(0deg);
}

button[aria-expanded='true'] .button-right-expand {
  transition: 0.3s transform ease-in-out;
  transform: rotate(180deg);
}

button[aria-expanded='false'] .button-right-expand {
  transition: 0.3s transform ease-in-out;
  transform: rotate(0deg);
}

// .fa-search {
//   color: #ffffff;
// }

.dashboard-card-title {
  font-size: 16px;
  font-weight: bold;
}

.card-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
}

.period-label {
  color: #ffffff;
  background-color: #000066;
  border-radius: 5px;
  font-weight: bold;
  padding: 5px 10px;
}

.export-button-other {
  position: 'absolute';
  background-color: '#04509c';
  right: 0;
  padding: '10px 5px 10px 20px';
  border-radius: '0 0 0 10px';
}

.source-label {
  color: gray;
  text-align: right;
}
.export-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  width: 3%;
  .dropdown-content {
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 10px rgba(0, 0, 0, 0.2);
    z-index: 10;
    border-radius: 10px;
    top: 35px;
    right: 0;
    a {
      color: #000;
      display: block;
      text-decoration: none;
      padding: 0 10px;
    }
    hr {
      margin: 0;
    }
    i {
      padding: 15px 0;
    }
  }
  i {
    color: #ffffff;
  }
}

::-moz-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 10px;
  height: 0 !important;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cfcfcf;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray;
}

$breakpoint-non-hd: 1366px;
@media screen and (max-width: $breakpoint-non-hd) {
  .dashboard-card-title {
    font-size: 10px;
  }

  .card-title {
    font-size: 12px;
  }
}

table {
  counter-reset: row-num-1;
}

table tr {
  counter-increment: row-num;
}

table tr .num::before {
  content: counter(row-num);
}

.image-icon:hover {
  cursor: pointer;
  opacity: 0.5;
}
