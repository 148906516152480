.label-input-select {
  font-weight: 600;
  font-size: 16px;
  color: #343434;
  margin-bottom: 8px;
}

.warning-message-select-option {
  font-weight: 400;
  font-size: 16px;
  color: #ff0000;
  margin-left: 10px;
}

.select__placeholder {
  color: #cccccc !important;
}