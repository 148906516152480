.dropdown-toggle::after {
  display: none;
}

.dropdown-menu-right {
  right: 0;
  left: unset !important;
}

.dropdown-icon {
  margin-right: 10px;
  width: 25px;
}

.dropdown-menu-navbar button {
  border: none;
  outline: none;
}

.dropdown-menu-navbar .btn {
  border: none;
  outline: none;
}

.dropdown-menu-navbar button:focus {
  border: none;
  outline: none;
}

.dropdown-menu-navbar .btn:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.button-primary,
.button-primary-blue,
.button-danger {
  height: 40px;
  background: #00bd84;
  border-radius: 50px;
  border: 1px solid #00bd84;
  font-size: 16px;
  color: #ffffff;
}

.button-danger {
  background: #dc2626;
  border: 1px solid #dc2626;
}

.button-primary-blue {
  background: #007aff;
  border: 1px solid #007aff;
}

.button-primary:hover,
.button-danger:hover,
.button-primary-blue:hover {
  cursor: pointer;
  opacity: 0.7;
}

.btn-disable {
  background: #e5e5e5;
  border: 1px solid #e5e5e5;
}

.btn-disable:hover {
  cursor: default;
  opacity: 1;
}

// outline
.button-outline-primary,
.button-outline-danger {
  height: 40px;
  background: #ffffff;
  border-radius: 50px;
  border: 1px solid #00bd84;
  font-weight: 600;
  font-size: 16px;
  color: #00bd84;
}

.button-outline-danger {
  border: 1px solid #dc2626;
  color: #dc2626;
}

.button-outline-primary:hover,
.button-outline-danger:hover {
  cursor: pointer;
  opacity: 0.7;
}
