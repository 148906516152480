@import "../../../assets/scss/abstracts/variables.scss";

.content-doctor-detail{
    .title{
        margin-top: 40px;
        margin-bottom: 30px;
        display: inline-block !important;
    }
    h1{
        font-size: 48px;
        text-align: center !important;
        margin-left: 230px;
        margin-right: 230px;
        display: inline-block !important;
    }
    .form-row>.col, .form-row>[class*=col-] {
        padding-right: 15px;
        padding-left: 15px;
        
    }

    .fa-minus{
        color: $color-grey2;
        font-size: 16px;
    }

    .start-grid{
        padding-left: 0px !important; 
    }
    .end-grid{
        padding-right: 0px !important; 
    }
    
    .input-group-text{
        border-top-right-radius: 50px !important;
        border-bottom-right-radius: 50px !important;
        border-color: $font-white;
        background: $font-white;
    }

    .btn-search{
        padding: 5px 12px;
        font-size: 16px;
        margin-left: 12px;
        border: none;
    }

    .card-detail-doctor{
        margin-bottom: 42px;
    }

    .fa-arrow-circle-right,
    .fa-map-marker{
        color: $color-blue;
    }

     .list-doctor {
        .photo-doctor{
            //margin:0;

            img{
                //border-radius: 50%;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                //margin: 12px;
                margin-left: -15px;
            }
        }
        .card{
            color: $font-black;
            border: 20px;
            .card-header{
                background: $font-white;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                
                .name-doctor{
                    font-size: 16px;
                    margin-top: 12px;
                    font-weight: 700;
                }
                .spesialis-doctor{
                    font-size: 16px;
                    margin-top: 8px;
                    font-weight: 400 !important;
                }
                .accordion-doctor{
                    font-size: 16px;
                    margin-top: 5px;
                    color: $color-primary;
                }

                .location-doctor{
                    font-size: 16px;
                    //color: $color-primary;
                    margin-top: 5px;
                    font-weight: 700;
                }

                .date-another-doctor{
                    //margin-top: 16px;
                    Button{
                        padding: 8px 32px;
                        font-size: 16px;
                        width: 365px
                    }
                }
                .condition-doctor,
                .performance-doctor,
                .education-doctor{
                    .title{
                        margin-top: 24px;
                    }
                }
            }
            .header-card{
                border-radius: 20px;
                border: 1px solid $color-grey1;
            }

            .card-body{
                padding-top: 0px;
                padding-left: 0px;
                padding-right: 0px;
                table{
                    margin-bottom: 0px;
                }
                .title{
                    margin: 0;
                    font-size: 16px;
                    background: $color-grey5;
                    padding: 8px 0 8px 15px;
                    font-weight: 400;
                    width: 100%;
                }
                .date-doctor{
                    border-bottom: 2px solid;
                    border-color: $color-grey5;

                    .list-date-doctor{
                        margin: 24px 88px;
                    }
                }

                .time-doctor-bpjs{
                    border-bottom: 2px solid;
                    border-color: $color-grey5;

                    .list-time-doctor-bjps{
                        margin: 24px 88px;
                    }
                }

                .submit-order{
                    margin-top: 24px;
                    button{
                        font-size: 16px;
                        border-radius: 20px;
                        padding: 8px 24px;
                    }
                }
                
                button{
                    font-size: 16px;
                    padding: 8px 24px;
                    margin-right: 24px;
                }

                .legend{
                    font-size: 16px;
                    font-weight: 400;
                    .row{
                        margin-left: 0px;
                        fa-circle{
                            font-size: 16px;
                            text-align: center;
                        }
                    }
                }
                // .available {
                //     color: $color-blue2 !important;
                //     background: none;
                // }
                .full {
                    color: $color-blue2 !important;
                    background: none;
                }
                .unavailable{
                    color: $color-grey7 !important;
                    background: none;
                }
                

            }
        }
        
    }
    .detail-doctor{
        margin-left: 90px;
        margin-bottom: 10px;
        .title {
            font-size: 16px !important;
            margin-bottom: 8px;
            font-weight: 700;
        }
        .content{
            img{
                margin-right: 6px;
            }
            font-size: 16px;
            text-align: left;
            ul{
                margin-left: 15px;
            }
        }
    }

    table{
        th,tr,td{
            width: 12.5%;
            border: none;
            height: 52px;
            align-items: center;
            p{
                font-size: 16px;
                color: $font-black;
            }
        }
        td{
            font-weight: 400;
        }
    }

    .list-date-availble{
        margin-bottom: -15px !important;
        .date-availble{
            height: 100%;
        }
    }
    
    .legend-container {
      margin-top: 10px;
      p {
        font-size: 16px;
        font-weight: 600;
        color: $font-black !important;
        margin-right: 10px;
      }
      .legend {
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 20px;
        margin-right: 5px;
      }
    }
    .legend-date {
      display: block;
      border-radius: 20px;
    }

    .available {
      background-color: $color-blue2 !important;
    }
    .full-waiting {
      background-color: $font-white;
      border: 1px solid $color-red1;
    }
    .full {
      background-color: $color-grey1 !important;
    }
    .unavailable {
      color: $color-grey7 !important;
      font-size: 35px !important;
      font-weight: 600 !important;
      margin: -8px 5px 0px 0px;
      vertical-align: top !important;
    }
}

.content-doctor-detail-mobile{
    .doctor-detail-mobile-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 18px;
        text-align: center;
        color: #ffffff;
        margin-top: 60px;
        margin-bottom: 20px;
    }

    .btn-search{
        padding: 5px 12px;
        font-size: 16px;
        border: none;
        width: 100%;
        margin-bottom: 10px;
    }

    .list-doctor {
        .photo-doctor{
            display: inline-block !important;
            vertical-align: top !important;
            margin-top: 20px;
            margin-right: 20px;
            img{
                border-radius: 50%;
            }
        }
        .card{
            color: $font-black;
            border: 20px;
            .card-header{
                background: $font-white;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;

                .profile-doctor{
                    display: inline-block !important;
                }
                
                .name-doctor{
                    font-size: 16px;
                    margin-top: 12px;
                    font-weight: 700;
                }
                .spesialis-doctor{
                    font-size: 16px;
                    margin-top: 8px;
                    font-weight: 400 !important;
                }
                .accordion-doctor{
                    font-size: 16px;
                    margin-top: 5px;
                    color: $color-primary;
                }

                .location-doctor{
                    font-size: 16px;
                    //color: $color-primary;
                    margin-top: 5px;
                    font-weight: 700;
                }

                .date-another-doctor{
                    margin-top: 10px;
                    margin-bottom: 10px;
                    Button{
                        padding: 5px 20px;
                        font-size: 16px;
                        width: 100%
                    }
                }
                .condition-doctor,
                .performance-doctor,
                .education-doctor{
                    .title{
                        margin-top: 24px;
                    }
                }
            }
            .header-card{
                border-radius: 20px;
                border: 1px solid $color-grey1;
            }

            .card-body{
                padding-top: 0px;
                padding-left: 0px;
                padding-right: 0px;
                table{
                    margin-bottom: 0px;
                }
                .title{
                    margin: 0;
                    font-size: 16px;
                    background: $color-grey5;
                    padding: 8px 0 8px 15px;
                    font-weight: 400;
                }
                .date-doctor{
                    border-bottom: 2px solid;
                    border-color: $color-grey5;

                    .list-date-doctor{
                        margin: 24px 88px;
                    }
                }

                .time-doctor-bpjs{
                    border-bottom: 2px solid;
                    border-color: $color-grey5;

                    .list-time-doctor-bjps{
                        margin: 24px 88px;
                    }
                }

                .submit-order{
                    margin-top: 24px;
                    button{
                        font-size: 16px;
                        border-radius: 20px;
                        padding: 8px 24px;
                    }
                }
                
                button{
                    font-size: 16px;
                    padding: 8px 24px;
                    margin-right: 24px;
                }

                .legend{
                    font-size: 16px;
                    font-weight: 400;
                    .row{
                        margin-left: 0px;
                        fa-circle{
                            font-size: 16px;
                            text-align: center;
                        }
                    }
                }
                .full {
                    color: $color-blue2 !important;
                    background: none;
                }
                .unavailable{
                    color: $color-grey7 !important;
                    background: none;
                }
                

            }
        }
    }

    .detail-doctor{
        // margin-left: 90px;
        margin-bottom: 10px;
        .title {
            font-size: 16px !important;
            margin-bottom: 5px;
            font-weight: 700;
        }
        .content{
            img{
                margin-right: 6px;
            }
            font-size: 16px;
            text-align: left;
            ul{
                margin-left: 15px;
            }
        }
    }

    .form-row>.col, .form-row>[class*=col-] {
        padding-right: 15px;
        padding-left: 15px;
        
    }

    .fa-minus{
        color: $color-grey2;
        font-size: 16px;
    }

    .start-grid{
        padding-left: 0px !important; 
    }

    .end-grid{
        padding-right: 0px !important; 
    }
    
    .input-group-text{
        border-top-right-radius: 50px !important;
        border-bottom-right-radius: 50px !important;
        border-color: $font-white;
        background: $font-white;
    }

    .card-detail-doctor{
        margin-bottom: 42px;
    }

    .fa-arrow-circle-right,
    .fa-map-marker{
        color: $color-blue;
    }
    table{
        th,tr,td{
            width: 12.5%;
            border: none;
            height: 52px;
            align-items: center;
            p{
                font-size: 16px;
                color: $font-black;
            }
        }
        td{
            font-weight: 400;
        }
    }

    .list-date-availble{
        margin-bottom: -15px !important;
        .date-availble{
            height: 100%;
        }
    }
    
    .legend-container {
      margin-top: 10px;
      p {
        font-size: 16px;
        font-weight: 600;
        color: $font-black !important;
        margin-right: 10px;
      }
      .legend {
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 20px;
        margin-right: 5px;
      }
    }
    .legend-date {
      display: block;
      border-radius: 20px;
    }

    .available {
      background-color: $color-blue2 !important;
    }
    .full-waiting {
      background-color: $font-white;
      border: 1px solid $color-red1;
    }
    .full {
      background-color: $color-grey1 !important;
    }
    .unavailable {
      color: $color-grey7 !important;
      font-size: 35px !important;
      font-weight: 600 !important;
      margin: -8px 5px 0px 0px;
      vertical-align: top !important;
    }
}

footer{
    p{
        position: relative;
    }
}