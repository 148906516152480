input {
  font-family: "Montserrat";
  border-radius: 50px !important;
  background: $font-white;
  border: none;
}

input:focus {
  border-color: #00bd84;
  outline: none;
}

textarea:focus {
  border-color: #00bd84;
  outline: none;
}

::placeholder {
  color: #cccccc !important;
  //opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #cccccc !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #cccccc !important;
}

select {
  color: $color-grey3 !important;
}
