@import '../../../assets/scss/abstracts/variables.scss';
html,
body {
  font-weight: bold;
}

input[type='number'] {
  height: 29px;
}
textarea {
  border-radius: 15px !important;
}

.modal-dialog {
  max-width: 50% !important;
  max-height: 50% !important;
}

.modal-content {
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 600px) {
  .modal-dialog {
    max-width: 250% !important;
    max-height: 50% !important;
  }

  .modal-content {
    max-width: 250%;
    margin-left: auto;
    margin-right: auto;
  }
}

.modal-header {
  color: $font-black !important;
  font-size: 24px;
  font-weight: 700;
}

.col-md {
  color: $font-black !important;
  font-weight: 600;
  font-size: 16px;
}

html,
label {
  color: $font-black !important;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  font-weight: 600;
}

.errorRequired {
  border: 1px solid red !important;
}

.span-error {
  font-size: 16px;
  color: red;
  font-weight: lighter;

  .fa {
    margin-right: 3px;
    margin-left: 10px;
  }
}

.btn-save {
  font-weight: lighter !important;
  color: $font-white !important;
  font-size: 16px !important;
  padding: 0px !important;
  border: none;
  height: 40px;
}

.btn-close {
  font-weight: lighter !important;
  background-color: $font-white !important;
  border-color: $font-white !important;
  background: $font-white !important;
  color: $border-circle-service !important;
  font-size: 16px !important;
  padding: 0px !important;
  border: 1px $border-circle-service solid !important;
  height: 40px;
}

.content-account-profile {
  .profile-header {
    margin-top: 40px;
    margin-bottom: 54px;
  }

  .navbar-profile-left {
    display: inline-block !important;
  }

  .navbar-profile-right {
    position: absolute;
    right: 0;
    display: inline-block !important;
    margin-right: 10px;
  }

  .btn-search {
    font-weight: bold;
    color: $font-white !important;
    font-size: 16px;
    padding: 5px 20px;
    margin-left: 12px;
    border: none;
    .fa {
      margin-right: 8px;
    }
  }

  .profil-label {
    font-weight: bold;
    color: $font-white !important;
    font-size: 18px;
    margin-right: 15px;
    display: inline-block !important;
  }

  .text-muted {
    padding: 5px;
    padding-left: 10px;
  }

  .list-profile {
    display: inline-block !important;
    width: 320px;
    margin-right: 30px;
    font-size: 16px;
    .header {
      background-color: #ededed !important;
      border-radius: 20px 20px 0px 0px;
      height: 215px;
      color: $font-black !important;
    }
    .body {
      color: $font-black !important;
      background-color: $font-white !important;
      border-radius: 0px 0px 50px 50px;
    }
  }

  .image-profile {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
  }

  .btn-add-profile {
    font-weight: bold;
    color: $font-black !important;
    font-size: 16px;
    width: 320px;
    border: none;
    background-color: #ededed !important;
    border-radius: 20px 20px 20px 20px;
    margin-right: 30px;
    .header-profile {
      background-color: transparent !important;
      border: none;
      height: 215px;
    }
    .body-profile {
      height: 148px;
    }
  }

  .image-btn-add-profile {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .setting-right {
    position: absolute;
    right: 0;
    margin-right: 20px;
    font-size: 17px;
    border: none;
    background-color: transparent !important;
    color: $font-black;
  }
  table td {
    border-top: 1px solid $font-white !important;
    border-bottom: 1px solid $font-white !important;
  }

  h4 {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    margin-top: 15px;
  }

  h6 {
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
  }

  .form-row > .col,
  .form-row > [class*='col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }

  .start-grid {
    padding-left: 0px !important;
  }

  .end-grid {
    padding-right: 0px !important;
  }

  .input-group-text {
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    border-color: $font-white;
    background: $font-white;
  }

  .btn-history {
    font-weight: bold;
    background: #ededed !important;
    color: $font-black !important;
    font-size: 12px;
    padding: 5px 10px;
    margin-left: 12px;
    border: none;
    .fa {
      margin-right: 6px;
    }
  }

  .btn-prev-next {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 20px;
  }
}

.card-list-profile {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(170, 170, 170, 0.64);
  border-radius: 16px;
  padding: 24px;
}

.title-card {
  font-weight: 700;
  font-size: 36px;
  color: #343434;
}

.col-list-profile {
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  margin: 0 2px;
}

.user,
.current-user {
  font-weight: 400;
  font-size: 12px;
  color: #424242;
}

.current-user {
  font-weight: 500;
}

// md size = 768px
@media only screen and (max-width: 768px) {
  .card-list-profile {
    padding: 10px;
  }

  .col-list-profile {
    margin: 0 2px;
  }

  .user,
  .current-user {
    font-size: 16px;
  }

  .full-name {
    font-size: 16px;
  }
}

.action-dropdown {
  border-radius: 0;
}

.modal-form {
  width: 100em;
  background: red;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.modal-form-body {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 100em;
  background: blue;
}
.container-modal {
  overflow-y: auto;
  max-height: 200px;
}
@media screen and (max-height: 640px) {
  .container-modal {
    max-height: 350px;
  }
}
@media screen and (min-height: 667px) {
  .container-modal {
    max-height: 370px;
  }
}

@media screen and (min-height: 700px) {
  .container-modal {
    max-height: 500px;
  }
}
@media screen and (min-height: 800px) {
  .container-modal {
    max-height: 600px;
  }
}

/* Mobile View */
.mobile-profile-name {
  font-weight: 700;
  font-size: 20px;
  color: #424242;
}

.mobile-profile-dob {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #343434;
  line-height: 16px;
}

.icon-edit {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.hr-no-margin {
  border: 1px solid #BBBABA;
}

.row-no-margin {
  width: calc(100% + 30px)!important;
}