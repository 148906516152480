@import '../../../../../assets/scss/abstracts/variables.scss';

footer {
  h3 {
    font-size: 16px;
    color: $font-white;
    padding-top: 90px;
    padding-bottom: 30px;
  }
  a {
    color: $color-primary;
  }
}
.margin-top-footer {
  margin-top: 50px;
}
@media only screen and (max-width: 600px) {
  footer {
    margin-top: 20px;
  }
}
