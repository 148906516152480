@import '../../../assets/scss/abstracts/variables.scss';

.content-janji-temu {
  height: 540px;
  .appointment {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  h1 {
    font-size: 64px;
    font-weight: bold;
  }
}
footer {
  p {
    position: fixed !important;
    bottom: 30px !important;
    padding-bottom: 0px !important;
  }
}

.mobile-title {
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  color: #ffffff;
  margin-top: 50px;
}

// .icon {
//   position: absolute;
//   right: 25px;
//   min-width: 20px;
//   padding-top: 8.5px;
// }

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

.input-field {
  width: 100%;
  padding: 8px 10px;
  height: 40px;
  font-weight: 400;
  font-size: 16px;
}
