$color-primary : #00BD84;
$color-primary-lightness1 : #99E5CE;
$color-primary-lightness2 : #D0FFF1;
$color-primary-lightness3 : #66D7B5;
$color-primary-lightness4 : #E6F8F3;
$background-color-primary : #0F7DA1;
$background-color-secondary : #00186F;
$background-color-service1: #0192C8;
$background-color-service2: #04327C;
$font-white : #FFFFFF;
$font-black : #000000;
$font-black2 : #26282B;
$font-black3 : #30242A;
$color-whatsapp : #0052B4;
$color-otp : #E6F8F3;
$color-grey1: #CCCCCC;
$color-grey2: #A1A4A8;
$color-grey3: #717171;
$color-grey4: #ABBED1;
$color-grey5: #EDEDED;
$color-grey6: #C2C2C2;
$color-grey7: #E3E3E3;
$color-card1: #F7F7F7;
$color-card2: #F6F6F6;
$border-circle-service: #00A07D;
$border-circle-service2: #00FFB5;
$color-blue: #007DFF;
$desc-appointment: "Layanan “Janji Temu” cara mudah pasien untuk melakukan \a pendaftaran konsultasi sebelum waktu kedatangan.";
$color-blue2:#E1F0FF;
$color-red1:#E02B1D;




